@if (isLoading) {
  <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
} @else {
  @if (accessCode) {
    <div class="access-code-label">{{ 'ACCESS_CODE' | lhTranslate }}</div>
    <div class="access-code">
      @for (digit of accessCode; track $index) {
        <span>{{ digit }}</span>
      }
    </div>
  } @else if (properties.showLuggageImage) {
    <lh-shared-feature-bag-image-input
      [isTakingPhoto]="false"
      [numberOfBags]="numberOfBags"
      [remoteLuggageImage]="remoteLuggageImage"
      [localLuggageImage]="localLuggageImage"
      [storageLocation]="storageLocation"
    ></lh-shared-feature-bag-image-input>
  }

  @if (properties.showStorageTimer) {
    <lh-shared-feature-bookings-timer [booking]="booking" [isCheckInSession]="true"></lh-shared-feature-bookings-timer>
  }
}
